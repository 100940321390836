import * as React from 'react';

import AppStoreBadge from 'src/assets/images/app-store-badge.svg';
import LogoSVG from 'src/assets/images/logo.svg';
import Screenshots from 'src/assets/images/app-screenshots.png';

import {
  StyledAppBadge,
  StyledAppScreenshots,
  StyledAppScreenshotsMobile,
  StyledLogo,
  StyledCapsuleButton,
} from './styled';

type ButtonProps = {
  className?: string;
  enabled?: boolean;
  handleSubmit?(): void;
  style?: React.CSSProperties;
  text?: string;
};

const defaultSubmit = (): void => {
  return;
};

export function AppScreenshotsMobile({ style }: ButtonProps): JSX.Element {
  return (
    <StyledAppScreenshotsMobile alt="Birthday App Screenshots" src={Screenshots} style={style} />
  );
}

export function AppScreenshots({ style }: ButtonProps): JSX.Element {
  return <StyledAppScreenshots alt="Birthday App Screenshots" src={Screenshots} style={style} />;
}

export function AppStoreButton({ className = '', style }: ButtonProps): JSX.Element {
  const openAppOnAppStore = () => {
    window.open(process.env.REACT_APP_APP_STORE_URL, '_blank');
  };

  return (
    <StyledAppBadge
      alt="Open Birthday App on the App Store"
      className={className}
      onClick={openAppOnAppStore}
      src={AppStoreBadge}
      style={{ cursor: 'pointer', ...style }}
    />
  );
}

export function CapsuleButton({
  enabled = true,
  handleSubmit,
  style,
  text,
}: ButtonProps): JSX.Element {
  return (
    <StyledCapsuleButton
      dark={enabled}
      onSubmit={enabled ? handleSubmit : defaultSubmit}
      style={style}
    >
      {text}
    </StyledCapsuleButton>
  );
}

export function Logo({ style }: ButtonProps): JSX.Element {
  return <StyledLogo alt="Birthday App Logo" src={LogoSVG} style={style} />;
}
