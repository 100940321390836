import styled from '@emotion/styled';
// import { PlusSign } from 'src/components/icons';
import { AppStoreButton } from 'src/components/buttons';

export const AppInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-left: 107px;
  }
`;

export const AppInfoDescription = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 19px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 21px;
    margin-top: 6px;
    text-align: left;
  }
`;

type SectionProps = {
  background: string;
  mobileBackground: string;
};

export const AppInfoSection = styled.section<SectionProps>`
  align-items: center;
  background: ${(props) => `url(${props.mobileBackground})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 78px;
  padding-bottom: 75px;
  padding-top: 51px;
  width: 100vw;

  @media (min-width: 768px) {
    background-image: ${(props) => `url(${props.background})`};
    flex-direction: row;
    margin-top: 90px;
    padding-bottom: 105px;
    padding-top: 112px;
  }
`;

export const AppInfoTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 42px;
    text-align: left;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-image: linear-gradient(#ffffff, #fffef5);
  display: flex;
  flex-direction: column;
`;

export const StyledAppStoreButton = styled(AppStoreButton)`
  align-self: center;
  margin-top: 93px;

  @media (min-width: 768px) {
    align-self: auto;
    margin-top: 69px;
  }
`;

export const ValueProp = styled.li`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 17px;
  line-height: 25px;
  margin-top: 26px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
`;

// list-style-image: ${() => `url(${PlusSign})`};
export const ValueProps = styled.ul`
  margin: 0px 32px 0px;
  text-align: left;

  @media (min-width: 768px) {
    margin: 8px 0px 0px;
    max-width: 385px;
  }
`;
