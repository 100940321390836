import styled from '@emotion/styled';

type StyleProps = {
  dark: boolean;
};

export const StyledAppBadge = styled.img`
  width: 179.27px;

  @media (min-width: 768px) {
    width: 195px;
  }
`;

export const StyledAppScreenshots = styled.img`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 556.16px;
  }
`;

export const StyledAppScreenshotsMobile = styled.img`
  display: block;
  margin-top: 25px;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const StyledCapsuleButton = styled.button<StyleProps>`
  background: ${(props) => props.theme.colors.darkGreyBlueTwo};
  border-radius: 100px;
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.colors.white};
  line-height: 22px;
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 15px;
  font-weight: 600;
  opacity: ${(props) => (props.dark ? 1 : 0.3)};
  padding: 12px 20px;
  text-align: center;
`;

export const StyledLogo = styled.img`
  margin-top: 110px;
  width: 203.64px;

  @media (min-width: 768px) {
    margin-top: 80px;
    width: 230px;
  }
`;
