import * as React from 'react';

import { Column } from 'src/components/elements';

import {
  ConfirmButton,
  ConfirmationText,
  Container,
  DayInput,
  DayLabel,
  InputContainer,
  MonthInput,
  MonthLabel,
} from './styled';

type FormEvent = {
  target: HTMLInputElement;
};

type Props = {
  birthday: string;
  onSubmit(): void;
  setBirthday: React.Dispatch<React.SetStateAction<string>>;
  update: boolean;
  validDate: boolean;
};

type Current = {
  focus(): void;
};

type Ref = {
  current: Current;
};

export default function ConfirmationForm({
  birthday,
  onSubmit,
  setBirthday,
  update,
  validDate,
}: Props): JSX.Element {
  const dayInputRef = React.useRef<Ref>({
    current: {
      focus: () => {
        /* do nothing */
      },
    },
  });
  const [month, setMonth] = React.useState(1);

  const changeDay = (event: FormEvent) => {
    const values = birthday.split('-');
    values[2] = event.target.value.replace(/\./g, '').slice(0, 2);

    setBirthday(values.join('-'));
  };

  const changeMonth = (event: FormEvent) => {
    const values = birthday.split('-');
    values[1] = event.target.value.replace(/\./g, '').slice(0, 2);

    setBirthday(values.join('-'));

    if (values[1].length == 2) {
      dayInputRef.current.focus();
    }
  };

  const validateDay = (event: FormEvent) => {
    const values = birthday.split('-');
    values[2] = validDay(event.target.value);

    setBirthday(values.join('-'));
  };

  const validateMonth = (event: FormEvent) => {
    const values = birthday.split('-');
    values[1] = validMonth(event.target.value);

    setBirthday(values.join('-'));
  };

  const validDay = (day: string): string => {
    let validDay = Math.max(parseInt(day.replace(/\./g, '') || '1'), 1);

    if (month == 2) {
      validDay = Math.min(validDay, 29);
    } else if ([4, 6, 9, 11].includes(month)) {
      validDay = Math.min(validDay, 30);
    } else {
      validDay = Math.min(validDay, 31);
    }

    return (validDay < 10 ? '0' : '') + validDay;
  };

  const validMonth = (month: string): string => {
    const validMonth = Math.min(Math.max(parseInt(month.replace(/\./g, '') || '1'), 1), 12);

    return (validMonth < 10 ? '0' : '') + validMonth;
  };

  React.useEffect(() => {
    if (birthday) {
      const month = birthday.match(/-([0-9]{0,2})-/);
      setMonth(parseInt(month ? month.pop() || '1' : '1'));
    }
  }, [birthday]);

  return (
    <Container>
      <ConfirmationText>
        Did we get your birthday right? If not, tap the month and day below to edit.
      </ConfirmationText>
      <InputContainer>
        <Column>
          <MonthLabel>Month</MonthLabel>
          <MonthInput
            autoFocus={!birthday.split('-')[1]}
            max={12}
            min={1}
            onBlur={validateMonth}
            onChange={changeMonth}
            placeholder="MM"
            size={2}
            step={1}
            type="number"
            value={birthday.split('-')[1]}
          />
        </Column>
        <Column style={{ marginLeft: 16 }}>
          <DayLabel>Day</DayLabel>
          <DayInput
            autoFocus={!!birthday.split('-')[1]}
            ref={dayInputRef}
            max={31}
            min={1}
            onBlur={validateDay}
            onChange={changeDay}
            placeholder="DD"
            size={2}
            step={1}
            type="number"
            value={birthday.split('-')[2]}
          />
        </Column>
      </InputContainer>
      <ConfirmButton enabled={validDate} onClick={onSubmit}>
        {update ? 'Update my birthday' : 'Yep, that’s correct!'}
      </ConfirmButton>
    </Container>
  );
}
