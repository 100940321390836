import styled from '@emotion/styled';

export const CallToActionText = styled.p`
  color: ${(props) => props.theme.colors.darkGreyBlueTwo};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 19px;
  font-weight: 300;
  line-height: 27px;
  margin-top: 21px;

  @media (min-width: 768px) {
    font-size: 21px;
    margin-top: 50px;
  }
`;

export const ConfirmationText = styled.p`
  color: ${(props) => props.theme.colors.darkGreyBlueTwo};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 21px;

  @media (min-width: 768px) {
    font-size: 21px;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding: 0px 40px;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 30px;
    width: 561px;
  }
`;
