import * as React from 'react';
import { Helmet } from 'react-helmet';
import { format, parse } from 'date-fns';
import qs from 'query-string';

import { AppScreenshots, AppScreenshotsMobile, AppStoreButton, Logo } from 'src/components/buttons';
import { useConfirmBirthday, useContactShortLink } from 'src/helpers/api';

import BackgroundImage from './images/background.svg';
import ConfirmationForm from './ConfirmationForm';
import MobileBackgroundImage from './images/mobile-background.svg';
import SuccessMessage from './SuccessMessage';
import {
  AppInfo,
  AppInfoDescription,
  AppInfoSection,
  AppInfoTitle,
  Container,
  StyledAppStoreButton,
  ValueProp,
  ValueProps,
} from './styled';

function Landing(): JSX.Element {
  const [birthdayFromParams, setBirthdayFromParams] = React.useState('1503--');
  const [birthday, setBirthday] = React.useState('1503--');
  const [confirmed, setConfirmed] = React.useState(false);
  const [formattedBirthday, setFormattedBirthday] = React.useState('');
  const [numbers, setNumbers] = React.useState<string[]>([]);
  const [setConfirmBirthday] = useConfirmBirthday();
  const [getContactShortLink, { data: contactShortLinkData }] = useContactShortLink();
  const { token = '' } = qs.parse(window.location.search);

  const confirmBirthday = () => {
    if (!validBirthday()) {
      console.error('Invalid birthday!');
      return;
    }

    if (numbers && numbers.length == 0) {
      console.error('No numbers were provided!');
      return;
    }

    setConfirmed(true);

    try {
      setConfirmBirthday({
        variables: {
          birthday: format(parseBirthday(), 'yyyy-MM-dd'),
          numbers,
        },
      });

      if (birthday != birthdayFromParams) {
        window.analytics.track('Update Birthday from Landing Page');
      } else {
        window.analytics.track('Confirm Birthday from Landing Page');
      }
    } catch (error) {
      console.error(`An error was encountered while confirming birthdays: ${error}`);
    }
  };

  const parseBirthday = (): Date => {
    return parse(birthday, 'yyyy-MM-dd', new Date());
  };

  const setData = (birthday: string, numbers: string[]) => {
    setBirthdayFromParams(birthday || '1503--');
    setBirthday(birthday || '1503--');
    setNumbers(numbers || []);
    window.analytics.identify('+1' + numbers[0], { phoneNumber: numbers[0] });
  };

  const stringFromToken = (): string => {
    return (Array.isArray(token) ? token[0] : token) || '';
  };

  const validBirthday = (): boolean => {
    return parseBirthday().toString() != 'Invalid Date';
  };

  React.useEffect(() => {
    if (token) {
      try {
        getContactShortLink({
          variables: {
            verificationCode: stringFromToken(),
          },
        });
      } catch (error) {
        console.error(`An error was encountered while fetching your data: ${error}`);
      }
    }
  }, [token]);

  React.useEffect(() => {
    if (contactShortLinkData && contactShortLinkData.contactShortLink) {
      if (contactShortLinkData.contactShortLink.success) {
        setData(
          contactShortLinkData.contactShortLink.birthday,
          contactShortLinkData.contactShortLink.numbers
        );
      } else {
        console.error(`An error was encountered while fetching your data.`);
        contactShortLinkData.contactShortLink.errors.forEach((error) => {
          console.error(`${error['field']}: ${error['messages']}`);
        });
      }
    }
  }, [contactShortLinkData]);

  React.useEffect(() => {
    window.analytics.page('Birthday Confirmation Landing Page');
  }, []);

  React.useEffect(() => {
    const parsedBirthday = parseBirthday();

    if (parsedBirthday.toString() != 'Invalid Date') {
      setFormattedBirthday(format(parsedBirthday, 'MM/dd'));
    }
  }, [birthday]);

  return (
    <Container>
      <Helmet>
        <link rel="canonical" href="https://hello.birthday.app/" />
        <meta charSet="utf-8" />
        <title>
          Birthday App - Your friend wants you to confirm that {formattedBirthday} is your birthday
          for their calendar.
        </title>
        <meta
          name="description"
          content={`Birthday App - Your friend wants you to confirm that ${formattedBirthday} is your
          birthday for their calendar.`}
        />
        <meta
          name="og:title"
          content={`Birthday App - Your friend wants you to confirm that ${formattedBirthday} is your
          birthday for their calendar.`}
        />
        <meta
          name="og:description"
          content={`Birthday App - Your friend wants you to confirm that ${formattedBirthday} is your
          birthday for their calendar.`}
        />
        <meta name="og:site_name" content="Birthday App" />
        <meta name="og:type" content="website" />
        <meta name="og:image" content="https://hello.birthday.app/favicon.ico" />
        <meta name="og:url" content={`https://hello.birthday.app/?token=${token}`} />
      </Helmet>
      <Logo />
      {confirmed ? (
        <SuccessMessage
          appStoreButton={<AppStoreButton style={{ marginTop: 94 }} />}
          birthday={formattedBirthday}
          update={birthday != birthdayFromParams}
        />
      ) : (
        <ConfirmationForm
          birthday={birthday}
          setBirthday={setBirthday}
          onSubmit={confirmBirthday}
          update={birthday != birthdayFromParams}
          validDate={validBirthday()}
        />
      )}
      <AppInfoSection background={BackgroundImage} mobileBackground={MobileBackgroundImage}>
        <AppScreenshots />
        <AppInfo>
          <AppInfoTitle>Be a better friend.</AppInfoTitle>
          <AppInfoDescription>Never miss a birthday again.</AppInfoDescription>
          <AppScreenshotsMobile />
          <ValueProps>
            <ValueProp>
              <strong>Don&apos;t lift a finger:</strong> We find all your friend&apos;s birthdays
              without you needing to input a thing.
            </ValueProp>
            <ValueProp>
              <strong>It&apos;s Safe:</strong> We only ever show the month and day.
            </ValueProp>
            <ValueProp>
              <strong>Your surprise is waiting:</strong> Give and receive gifts all year long.
            </ValueProp>
          </ValueProps>
          <StyledAppStoreButton />
        </AppInfo>
      </AppInfoSection>
    </Container>
  );
}

export default Landing;
