import { gql, MutationTuple, useMutation } from '@apollo/client';

const CONTACT_SHORT_LINK = gql`
  mutation ContactShortLink($verificationCode: String!) {
    contactShortLink(verificationCode: $verificationCode) {
      errors {
        field
        messages
      }
      birthday
      numbers
      success
    }
  }
`;

type ErrorType = {
  field: string;
  messages: string[];
};

type ContactShortLinkDataType = {
  contactShortLink: {
    errors: ErrorType[];
    birthday: string;
    numbers: string[];
    success: boolean;
  };
};

type ContactShortLinkVariablesType = {
  verificationCode: string;
};

export function useContactShortLink(): MutationTuple<
  ContactShortLinkDataType,
  ContactShortLinkVariablesType
> {
  return useMutation<ContactShortLinkDataType, ContactShortLinkVariablesType>(CONTACT_SHORT_LINK);
}
