import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { SegmentAnalytics } from '@segment/analytics.js-core';
import { ThemeProvider } from '@emotion/react';

import 'normalize.css';
import 'src/index.css';
import Landing from 'src/views/Landing/index';
import reportWebVitals from 'src/reportWebVitals';
import theme from 'src/theme';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

if (process.env.NODE_ENV == 'production') {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY,
    });
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route component={Landing} path="/" />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
