import { gql, MutationTuple, useMutation } from '@apollo/client';

const SET_CONFIRM_BIRTHDAY = gql`
  mutation ConfirmBirthday($birthday: Date!, $numbers: [String]!) {
    confirmBirthday(birthday: $birthday, numbers: $numbers) {
      success
    }
  }
`;

type SetConfirmBirthdayDataType = {
  confirmBirthday: {
    success: boolean;
  };
};

type SetConfirmBirthdayVariablesType = {
  birthday: string;
  numbers: string[];
};

export function useConfirmBirthday(): MutationTuple<
  SetConfirmBirthdayDataType,
  SetConfirmBirthdayVariablesType
> {
  return useMutation<SetConfirmBirthdayDataType, SetConfirmBirthdayVariablesType>(
    SET_CONFIRM_BIRTHDAY
  );
}
