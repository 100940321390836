import '@emotion/react';
import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    colors: Record<string, string>;
    fonts: {
      poppins: string;
      sourceSansPro: string;
    };
  }
}

const theme: Theme = {
  colors: {
    aquaMarine: '#2fc8e4',
    black: '#000',
    black30: 'rgba(0, 0, 0, 0.3)',
    blueGrey: '#8e8e93',
    blueyGrey: '#8891ae',
    darkGreyBlue: '#27465b',
    darkGreyBlueTwo: '#2e3757',
    darkGreyBlueTwo10: 'rgba(46, 55, 87, 0.1)',
    darkGreyBlueTwo20: 'rgba(46, 55, 87, 0.2)',
    darkGreyBlueTwo30: 'rgba(46, 55, 87, 0.3)',
    darkGreyBlueTwo50: 'rgba(46, 55, 87, 0.5)',
    green: '#76ddab',
    macaroniAndCheese: '#ffbc2b',
    offWhite: '#fffef6',
    purple20: 'rgba(118, 122, 221, 0.2)',
    reddishOrange: '#fe5218',
    reddishOrangeDarken: '#e53900',
    seafoamBlue: '#76ddab',
    softBlue: '#767add',
    veryLightPink: '#f3f3f3',
    white: '#fff',
    white2: '#fafafa',
  },
  fonts: {
    poppins: 'Poppins, sans-serif',
    sourceSansPro: 'Source Sans Pro, sans-serif',
  },
};

export default theme;
