import styled from '@emotion/styled';

type ButtonProps = {
  enabled?: boolean;
};

export const ConfirmButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.darkGreyBlueTwo};
  border-radius: 100px;
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  margin: 76px auto 0px;
  opacity: ${(props) => (props.enabled ? 1 : 0.3)};
  padding: 14px 42px;
  width: fit-content;

  @media (min-width: 768px) {
    margin-top: 56px;
    padding: 19px 42px;
  }
`;

export const ConfirmationText = styled.p`
  color: ${(props) => props.theme.colors.darkGreyBlueTwo};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 19px;
  line-height: 27px;
  margin: 30px 40px 0px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 21px;
    line-height: 29px;
    margin-top: 50px;
    width: 400px;
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const DayLabel = styled.label`
  color: ${(props) => props.theme.colors.reddishOrange};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
`;

export const DayInput = styled.input`
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.reddishOrange}`};
  border-radius: 10px;
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.colors.reddishOrange};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  padding: 14px;
  text-align: center;
  width: 45px;

  ::placeholder {
    color: ${(props) => props.theme.colors.reddishOrange};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  margin: 22px auto 0px;

  @media (min-width: 768px) {
    margin-top: 26px;
  }
`;

export const MonthInput = styled.input`
  background: ${(props) => props.theme.colors.white};
  border: ${(props) => `1px solid ${props.theme.colors.softBlue}`};
  border-radius: 10px;
  box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.1);
  color: ${(props) => props.theme.colors.softBlue};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 25px;
  font-weight: 600;
  line-height: 22px;
  padding: 14px;
  text-align: center;
  width: 45px;

  ::placeholder {
    color: ${(props) => props.theme.colors.softBlue};
  }
`;

export const MonthLabel = styled.label`
  color: ${(props) => props.theme.colors.softBlue};
  font-family: ${(props) => props.theme.fonts.poppins};
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
`;
