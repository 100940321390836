import * as React from 'react';

import { CallToActionText, ConfirmationText, Container } from './styled';

type Props = {
  appStoreButton: JSX.Element;
  birthday: string;
  update?: boolean;
};

export default function SuccessMessage({
  appStoreButton,
  birthday,
  update = false,
}: Props): JSX.Element {
  const updated = `Great! We have updated your birthday to ${birthday}`;
  const confirmed = 'Great! Thanks for confirming your birthday';

  return (
    <Container>
      <ConfirmationText>{update ? updated : confirmed}.</ConfirmationText>
      <CallToActionText>
        Never want to miss another friend’s birthday? Download the app today or scroll down to learn
        more.
      </CallToActionText>
      {appStoreButton}
    </Container>
  );
}
